<template>
		<header class="ColorSkin">
			<div class="Container">
				<div class="Box">
					<div class="Logo">
						<a href="/">
							<img src="images/Logo.png" srcset="images/LogoRetina.png 2x" alt="Studio Sport">
						</a>
					</div>
				</div>
			</div>
		</header>
    		<section class="SigninSection">
			<div class="Container">
				<div class="Box">
					<h1>Oops!</h1>
					<h2>L'operazione è stata annullata. </h2>
					<div class="TxtEditor">
					<br/>
						<div class="Button">
							<a href="#/">Torna alla home page</a>
						</div>
					</div>
					<br/>
					<div class="TxtEditor">
					<div class="Button">
						<a href="#/home/carrello">Torna al carrello</a>
					</div>
				</div>
				</div>
			</div>
		</section>
</template>

<script>
import OrderService from "@/services/order.service.js";
export default {
	name: "Annullo",
    data(){
        return {
			Transazione: [],
        }
    },
	mounted: function(){
		this.Transazione = this.$route.query;
		if(this.Transazione != null){
			OrderService.SendTrans(this.$store.getters.getToken, this.$store.getters.getShop, this.Transazione);
		}
	}
}
</script>
